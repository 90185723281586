import { Atom, log, state } from ":mods";
import { createResidencyInfoInputs } from "../../form";
import { CountriesListResponse, FormPageProps } from "../../model";
import { Show, createEffect, createSignal, untrack } from "solid-js";
import { STUDENT_FORM_STEPS } from "../../const";
import { getCountries, getRegions } from "../../api";

export function ResidencyPage(props: FormPageProps) {
  const [regionsList, setRegionsList] = createSignal([]);
  const [regionsOptions, setRegionsOptions] = createSignal([]);
  const $countriesList = state.create([]);
  const $db_countries = state.createAsync(getCountries);

  const FormInputs = createResidencyInfoInputs();

  createEffect(() => {
    // console.log("countries ", $countriesList.value);
  });
  createEffect(() => {
    if ($db_countries.value) {
      untrack(() => {
        const countries = ($db_countries.value as CountriesListResponse[]) || [];
        const countries_names = countries.map((c) => c?.name);
        $countriesList.set(countries_names);
        const sa_countries = countries.filter((c) => c?.iso2?.toUpperCase() === "SA");
        if (!sa_countries) {
          log.all.error("no saudi arabia countries");
          return;
        }
        const saudi = sa_countries[0];
        getRegions(saudi.id).then((response) => {
          // console.log("regions :: ", response);
          setRegionsList(response?.data);
          setRegionsOptions(response?.data?.map((r) => r?.name));
        });
      });
    }

    if ((FormInputs.IsSaudiResident.control.value as string) === "false") {
      FormInputs.Country.control.markRequired(true);
      FormInputs.Region.control.markRequired(false);
      FormInputs.Region.control.setValue(undefined);
    } else {
      FormInputs.Region.control.markRequired(true);
      FormInputs.Country.control.markRequired(false);
      FormInputs.Country.control.setValue(undefined);
    }
  });
  const onSubmit = async () => {
    const values = FormInputs.Actions.getValuesSnakecase();
    if (!!values.country) {
      values.country = $db_countries.value.find((c) => c.name === values.country)?.id;
    }
    if (!!values.region) {
      values.region = regionsList().find((r) => r.name === values.region)?.id;
    }
    props.onFormSubmit(values);
  };

  return (
    <section class={`flex flex-col justify-center items-center bg#paper w-screen text-16px ${props.class ?? ""}`}>
      <Atom.CircleProgress.CircleProgressbar steps={STUDENT_FORM_STEPS} current_step={2} />
      <h1 class="text-28px font-medium my-40px text-start w-630px ">Please tell us about your Nationality </h1>

      <form class="flex flex-col items-left gap-20px text-16px pt-40px w-630px">
        <div class="flex flex-col gap-10px">
          <FormInputs.IsSaudi.Label title="Are you a Saudi Citizen?" class="mb-10px">
            <FormInputs.IsSaudi.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.IsSaudi.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.IsSaudi.Input type="radio" class="" placeholder="" name="IsSaudi" id="true" />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.IsSaudi.Input type="radio" class="bg#p" placeholder="" name="IsSaudi" id="false" />
            <p class="text-16px">No</p>
          </div>
        </div>
        <Show when={!!FormInputs.IsSaudi.control.value}>
          <div class="flex flex-col gap-10px">
            <FormInputs.IsSaudiResident.Label title="Are you a Saudi Resident?" class="mb-10px">
              <FormInputs.IsSaudiResident.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.IsSaudiResident.Label>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.IsSaudiResident.Input type="radio" class="" placeholder="" name="IsSaudiResident" id="true" />
              <p class="text-16px">Yes</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.IsSaudiResident.Input
                type="radio"
                class=""
                placeholder=""
                name="IsSaudiResident"
                id="false"
              />
              <p class="text-16px">No</p>
            </div>
          </div>
        </Show>
        <Show when={(FormInputs.IsSaudiResident.control.value as string) == "false"}>
          <div class="flex flex-col gap-10px">
            <FormInputs.Country.Label title="Where do you live?" class="">
              <FormInputs.Country.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.Country.Label>
            <FormInputs.Country.Select
              class="form-select border-2 border#p !bg#paper border-solid text-0.8rem"
              placeholder=""
              options={$countriesList.value}
              optionGroupClass={"!text#p "}
            />
          </div>
        </Show>

        <Show when={(FormInputs.IsSaudiResident.control.value as string) == "true"}>
          <div class="flex flex-col gap-10px">
            <FormInputs.Region.Label title="What is your Region ?" class="">
              <FormInputs.Region.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.Region.Label>
            <FormInputs.Region.Select
              class="form-select border-2 border#p !bg#paper border-solid text-0.8rem"
              placeholder=""
              options={regionsOptions()}
              optionGroupClass={"!text#p"}
            />
          </div>
        </Show>
        <div class="flex justify-end gap-1rem">
          <Atom.Form.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 w-35px h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
            controls={[]}
            onclick={props.onBack}
            statusValid={"Back"}
          />
          <FormInputs.Actions.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 w-35px h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
            onclick={onSubmit}
            statusValid={"Next"}
            statusInvalid={"Next"}
          />
        </div>
      </form>
    </section>
  );
}
